.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

.contentBlock {
  padding: 24px 0;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-primary-color);
}

.contentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  background-color: var(--bg-primary-color);
  height: 100%;
  width: 400px;
}

.contentChildren {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.contentChildren main {
  /* border: 1px solid red; */
  gap: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.title {
  width: 100%;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: -0.02em;
  text-align: center;
  font-weight: 600;
  color: var(--text-primary-color);

  
}
@media (max-width: 1440px) {
  .contentContainer {
    /* border: 1px solid red; */
    /* gap: 48px; */
  }
  .contentChildren main {
    /* gap: 16px; */
  }
}
@media (max-width: 768px) {
  .contentContainer {
    width: 100%;
    max-width: 540px;
    margin: 0 auto;
  }
}
@media (max-width: 558px) {
  .contentContainer {
    padding: 24px 16px;
  }
}

@media (max-width: 430px) {
  .title {
    font-size: 30px;
    line-height: 36px;
  }
}
