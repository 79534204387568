.button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 500;
}

/* a.button:hover {
  transform: none !important;
} */
.button:disabled {
  cursor: default;
}
.button.animated {
  transition: transform 0.3s ease;
}
.button:not(:disabled).animated:hover {
  transform: translateY(-4px);
}
.button svg {
  flex-shrink: 0;
}

.button span {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 215px; /* 190px; */
}

.xlarge {
  gap: 12px;
  font: var(--font-base);
  padding: 16px 24px;
  border-radius: 6px;
}

.large {
  gap: 8px;
  font: var(--font-base);
  padding: 16px 24px;
  border-radius: 4px;
}

.medium {
  gap: 8px;
  font: var(--font-small);
  padding: 12px;
  border-radius: 4px;
}
.small.square,
.medium.square {
  padding: 12px;
}

.small {
  gap: 8px;
  font: var(--font-small);
  padding: 12px;
  border-radius: 3px;
}

.xsmall {
  gap: 6px;
  font-size: 14px;
  line-height: 16.8px;
  padding: 10px 12px;
  border-radius: 3px;
}

.xsmall.square {
  padding: 10px;
}

.xxsmall {
  gap: 6px;
  font: var(--font-small);
  padding: 6px 8px;
  border-radius: 4px;
}

.xxxsmall.square,
.xxsmall.square {
  padding: 3px;
}

.xxxsmall {
  gap: 6px;
  font: var(--font-xs);
  padding: 8px;
  border-radius: 3px;
}

.micro {
  font-size: 11px;
  border-radius: 6px;
  padding: 0 4px;
}

.button.square_small {
  width: 32px;
  height: 32px;
  border-radius: 8px;
}
.button.square_small > svg {
  width: 16px;
  height: 16px;
}
.w100 {
  width: 100%;
}

.blue {
  border: 1px solid var(--c-brand-500);
  color: white;
  background: var(--c-brand-500);
}

/* .blue path {
  stroke: white;
} */

.blue:hover {
  background: var(--c-brand-600);
}

.blue:active,
.blue.actived {
  background-color: var(--c-brand-700);
}

.blue:focus-visible,
.clear:focus-visible,
.red:focus-visible,
.black:focus-visible,
.actioned {
  box-shadow: var(--focus-brand-500);
}

.blue:disabled,
.red:disabled {
  border: 1px solid var(--secondary-color-500);
  background-color: var(--secondary-color-500);
}

.white {
  border: 1px solid var(--border-primary-color);
  color: var(--text-primary-color);
  background-color: var(--bg-primary-color);
}

.white path {
  stroke: var(--text-primary-color);
}

.white:not(:disabled):hover {
  background: var(--bg-light-color);
}

.white:focus-visible {
  background-color: var(--bg-light-color);
  box-shadow: 0px 0px 0px 4px #66708533;
}
.white:disabled {
  color: var(--secondary-color-400);
  background-color: var(--bg-secondary-color);
}

.white:disabled path {
  stroke: var(--secondary-color-400);
}

.gray {
  border: 1px solid var(--secondary-color-100);
  color: var(--secondary-color-900);
  background-color: var(--secondary-color-50);
}
.gray:not(.actived):not(:disabled):hover {
  background-color: var(--secondary-color-200);
}

.clear {
  border: 1px solid transparent;
  color: var(--text-primary-color);
  /* background-color: var(--bg-primary-color); */
  background-color: transparent;
}

/* .clear path,
.clear circle,
.clear rect {
  stroke: var(--text-secondary-color);
} */
.clear.actived,
.activeLink {
  background-color: var(--bg-third-color);
}

.clear:not(.actived):not(:disabled):hover,
.clear.activeLink:not(.actived):hover {
  background-color: var(--bg-secondary-color);
}
.gray:focus-visible,
.clear:focus-visible {
  background-color: var(--secondary-color-100);
}

.gray:disabled,
.clear:disabled {
  color: var(--secondary-color-300);
  background-color: var(--bg-secondary-color);
}

.gray:disabled path,
.clear:disabled path {
  stroke: var(--secondary-color-300);
  fill: transparent;
}
.clear::not(:disabled):active {
  background-color: var(--secondary-color-200) !important;
}

.red {
  border: 1px solid var(--c-system-error-500);
  color: white;
  box-shadow: 0px 1px 2px 0px #0010180d;

  background: var(--c-system-error-500);
}

.red:hover {
  background: var(--c-system-error-600);
}

.black {
  background-color: var(--bg-inverted-primary-color);
  color: var(--text-inverted-primary-color);
  border: 1px solid var(--bg-inverted-primary-color);
}

.black path {
  /* fill: var(--text-inverted-primary-color); */
  stroke: var(--text-inverted-primary-color);
}
.black:disabled {
  color: var(--secondary-color-300);
  background-color: var(--secondary-color-200);
  border: 1px solid var(--secondary-color-200);
}

.gradient {
  background: linear-gradient(90deg, #0084ff 0%, #c974fe 75%, #c974fe 100%);
  border: none;
  color: white;
}
.gradient:hover {
  background: linear-gradient(90deg, #155eef 0%, #c974fe 75%, #c974fe 100%);
  /* background: var(--c-brand-500); */
}
.gradient:disabled {
  background: linear-gradient(
    90deg,
    rgba(0, 132, 255, 0.4) 0%,
    rgba(201, 116, 254, 0.4) 75%,
    rgba(201, 116, 254, 0.4) 100%
  );
  /* color: var(--text-primary-color); */
  /* border: 1px solid var(--border-primary-color); */
}
.circle {
  border-radius: 50%;
}

.center {
  justify-content: center;
  text-align: center;
}
.start {
  justify-content: flex-start;
  text-align: start;
}

.end {
  justify-content: flex-end;
  text-align: end;
}
.between {
  justify-content: space-between;
}
@media (max-width: 430px) {
  .large {
    font-size: 16px;
    line-height: 24px;
    gap: 6px;
    padding: 12px;
    border-radius: 8px;
  }
}
