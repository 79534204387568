.sendMessageContainer {
  display: flex;
  align-items: center;
  border-top: 1px solid var(--theme-chat-border);
  padding-left: 24px;
  padding-right: 24px;
  background: var(--theme-chat-background-text-field);

  border-bottom-right-radius: 12px;
}

.input {
  flex: 1;
  resize: none;
  padding: 10px 24px;
  border-radius: 8px;
  font-size: 15px;
  outline: none;
  font-family: inherit;
  //background-color: #f9f9f9;
  background: var(--theme-chat-background-text-field);
  display: flex;
  color: var(--text-primary-color);
}

.sendButton {
  margin-left: 10px;
  //padding: 10px 20px;
  background-color: #006cef;
  color: #fff;
  width: 48px;
  height: 48px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 15px;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: #025ecd;
  }

  &:disabled {
    background-color: #ddd;
    opacity: 0.65;
  }
}
