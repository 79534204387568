
.sidebarBoxInput {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-primary-color);
  border-radius: 5px;
}

.sidebarBoxInput:focus-within {
  box-shadow: var(--focus-brand-500);
}

.expandedTextareaOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  backdrop-filter: blur(24px);
  background: rgba(16, 24, 24, 0.24); /* Переведен #1010183D в rgba */
  padding: 80px 160px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.expandedTextareaOverlay .sidebarBoxInput {
  width: 100%;
  height: 100%;
  background: var(--bg-primary-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.textareaContainer {
  min-height: 78px;
  padding: 2px;
  height: 100%; /* ??? */
}
.textareaPrompt {
  width: 100%;
  height: 100%;
  resize: none;
  padding: 10px 14px;
  color: var(--text-primary-color);
  font-weight: 400;
  font-size: 14px;
  line-height: 18.2px;
  overflow: auto;
  background-color: transparent;
}

.textareaPrompt:focus {
  outline: none;
}

.textareaPrompt::-webkit-scrollbar {
  background-color: transparent;
  border-radius: 12px;
  width: 6px;
}
.textareaPrompt::-webkit-scrollbar-thumb {
  background-color: var(--border-primary-color);
  border-radius: 12px;
  width: 6px;
}
.botTextMessage {
  font-size: 14px;
  padding: 12px;
  color: var(--text-third-color);
}
.sidebarBoxBtn {
  display: flex;
  justify-content: space-between;
  padding: 6px 12px 6px 16px;
  align-items: center;
}

.sidebarBoxBtn svg {
  width: 16px;
  height: 16px;
}
.sidebarBtnBar {
  display: flex;
  align-items: center;
  gap: 4px;
}

.sidebarBtnBarBtn {
  padding: 6px;
  border-radius: 3px;
}
