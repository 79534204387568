.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  padding: 6px;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  width: fit-content;
  height: 24px;
}

.black {
  background-color: var(--secondary-color-900);
  color: white;
}

.light-gray {
  background-color: var(--secondary-color-25);
  color: var(--secondary-color-900);
}

.dark-gray {
  background-color: var(--secondary-color-700);
  color: var(--secondary-color-400);
}
.blue {
  background-color: var(--c-brand-500);
  color: white;
}

.light-blue {
  background-color: var(--c-brand-100);
  color: #101018;
}

.error {
  background-color: var(--c-system-error-500);
  color: var(--c-system-error-800);
}

.light-error {
  background-color: #0084ff33;
  color: #0084ff33;
}

.warning {
  background-color: var(--c-system-warning-300);
  color: var(--c-system-warning-500);
}

.light-warning {
  background-color: var(--c-system-warning-100);
  color: var(--c-system-warning-300);
}

.white {
  border: 1.5px solid #f5f7fa;
  color: #475467;
  background-color: #fff;
}
