.overlay {
  padding: 60px;
}
.container {
  display: flex;
  gap: 12px;
  width: 100%;
  height: 670px;
}

.blockContainer {
  background-color: var(--bg-primary-color);
  border-radius: 8px;
}
.sideContainer {
  height: 100%;
  width: 300px;
  flex-shrink: 0;
  padding: 16px;
}

.secondaryText {
  font: var(--font-base);
  color: var(--text-forth-color);
}

.contentContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 800px;
  /* gap: 24px; */
}

.buttonContainer {
  position: absolute;
  z-index: 5;
  min-width: 70px;
  max-width: 120px;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 16px;
  flex-shrink: 0;
}

.buttonContainer.leftPart {
  left: 0;
}
.buttonContainer.rightPart {
  right: 0;
}

.button {
  margin: auto 0;
  display: none;
}
.close {
  display: block;
}

.button svg path {
  stroke: black;
}

.img {
  max-height: 670px;
  object-fit: cover;
}

.imagesPreviewContainer {
  height: 670px;
  gap: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-y: scroll;
  flex-shrink: 0;
}
.imagesPreviewContainer::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.imagesPreviewContainer::-webkit-scrollbar {
  background-color: transparent;
}

.imagePreview {
  height: 60px;
  width: 60px;
  border-radius: 8px;
  object-fit: cover;
  border: 1px solid transparent;
  cursor: pointer;
}
.imagePreview.active {
  border: 1px solid var(--c-brand-500);
}

.videoTypeItem {
  width: 100%;
  height: 100%;
}
@media (max-width: 1350px) {
  .contentContainer {
    min-width: auto;
  }
}
@media (max-width: 1100px) {
  .overlay {
    padding: 24px;
  }
  .container {
    flex-direction: column;
    height: 100%;
  }
  .sideContainer {
    order: 2;
    height: auto;
    width: 100%;
  }
  .imagesPreviewContainer {
    flex-direction: row;
    width: 97vw;
    height: auto;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .contentContainer {
    height: 600px;
    flex-shrink: 0;
    min-width: 100%;
  }

  .img {
    max-height: 600px;
  }
  .imagesPreviewContainer > div {
    width: 100%;
    width: 60px;
    flex-shrink: 0;
    height: 60px;
  }
  .imagePreview {
    height: 100%;
  }
  .buttonContainer {
    position: absolute;
    top: 0;
    z-index: 50;
    max-width: 50px;
    min-width: 50px;
    padding: 0;
  }
  .button {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .overlay {
    padding: 15px;
  }

  .contentContainer {
    height: 480px;
    flex-shrink: 0;
  }
  .img {
    max-height: 480px;
  }
}
