.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 12px; */
  border-radius: 8px;
  width: fit-content;
  background-color: var(--bg-secondary-color);
  border: 1px solid var(--border-primary-color);
  color: var(--text-primary-color);
  width: 320px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}

.container.shown {
  opacity: 90%;
  visibility: visible;
  transition: all 0.5s ease;
}

.notification {
  padding: 16px;
  padding-bottom: 12px;
  width: 100%;
  /* background-color: red; */
  display: flex;
  gap: 8px;
  align-items: center;
  flex-shrink: 0;
}
.notification svg {
  flex-shrink: 0;
}

.loaderContainer {
  width: 100%; /* Ширина контейнера, можно изменить по желанию */
  border-radius: 0 0 12px 12px;
  overflow: hidden; /* Убираем выходящие элементы */
  height: 8px;
}
.loader {
  height: 100%;
  width: 0; /* Начальная ширина загрузчика */
  background-color: #4caf50;
  transition: width 0.2s linear; /* Переход ширины */
}

.loader.success {
  background-color: var(--c-brand-500);
}

.loader.error {
  background-color: var(--c-system-error-600);
}
.loader.alert {
  background-color: var(--c-system-warning-500);
}
.loader.info {
  background-color: var(--c-primary-gray-400);
}
