.modelModal {
  top: 58px;
  right: 0;
  width: 390px;
  text-align: start;
  padding: 0;
  height: 484px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  right: 0%;
  transform: translateX(-0%);
}

.modelContainer {
  position: relative;
}

.modalHeader {
  position: absolute;
  top: 0;
  background-color: var(--bg-primary-color);
  border-radius: 12px 12px 0 0;
  padding-bottom: 8px;
  border: none;
  width: 100%;
}
.models {
  padding: 4px;
  margin-right: 4px;
  margin-top: 92px;
  overflow-y: auto;
  max-height: 440px;
  padding-right: 4px;
}
.models::-webkit-scrollbar {
  background-color: transparent;
  width: 6px;
  padding-right: 4px;
}
.models::-webkit-scrollbar-thumb {
  width: 6px;
  background-color: var(--border-primary-color);
  border-radius: 8px;
  padding-right: 4px;
}
.gptFamily.noAccess p {
  opacity: 0.5;
}
.gptFamily.noAccess .gptFamilyModel:hover {
  cursor: default !important;
  background-color: var(--bg-primary-color);
}
.gptFamilyName {
  padding: 12px 16px;
  font-size: 14px;
  line-height: 16.8px;
  color: var(--text-secondary-color);
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
}
.gptFamilyModel {
  text-align: start;
  padding: 8px 16px;
  display: flex;
  gap: 12px;
  color: var(--text-primary-color);
}
.gptFamilyModel.activeGptModel {
  cursor: default !important;
  background-color: var(--bg-secondary-color);
}
.gptFamilyModel:hover {
  background-color: var(--bg-secondary-color);
  cursor: pointer;
}
.gptFamilyModel svg {
  flex-shrink: 0;
}
.modelDescription {
  font-size: 14px;
  line-height: 21px;
  color: var(--text-forth-color);
}
.tokens {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 4px;
  font-size: 14px;
  width: 50px;
}
.tokens svg:last-child {
  height: 16px;
  width: 16px;
}
.tokens svg:last-child path {
  fill: var(--text-forth-color);
}

.workType {
  padding: 12px 16px;
  font-size: 14px;
  line-height: 21px;
  color: var(--text-primary-color);
  font-weight: 500;
}
.toggle {
  border-radius: 999px;
  padding: 4px 10px;
  border: 1px solid var(--border-primary-color);
  font-size: 14px;
  align-items: 21px;
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  width: fit-content;
}
.toggle svg {
  width: 16px;
  height: 16px;
}
.toggle.activeToggle {
  background-color: var(--c-brand-100);
  border-color: transparent;
}
.tariffName {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(
    90deg,
    #0084ff 0%,
    #c25fff 76.17%,
    #c974fe 86.68%,
    #f5f7fa 152.35%
  );
}

.modelUsageType {
  border-radius: 999px;
  border: 1px solid var(--border-secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
}
.modelUsageType svg {
  height: 14px;
  width: 14px;
}
@media (max-width: 1160px) {
  .modelBtn {
    border-radius: 12px;
    padding: 10px 16px;
    border: 1px solid var(--border-primary-color);
    box-shadow: 0px 1px 2px 0px #6670851a;
  }
  .modelModal {
    left: 50%;
    transform: translateX(-50%);
  }
}
