/* Card styles */
.card {
  height: 100%;
  /* width: 100%; */
  overflow: hidden;
  border-radius: 5px;
}

.cardImage.loading {
  height: 270px;
  width: 100%;
}

/* The loading Class */
.loading {
  position: relative;
  background-color: var(--bg-secondary-color);
}

/* The moving element */
.loading::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    color-stop(rgba(255, 255, 255, 0.2)),
    to(transparent)
  );

  background: linear-gradient(
    90deg,
    transparent,
    transparent,
    rgba(255, 255, 255, 0.763),
    transparent,
    transparent,
    transparent
  );

  /* Adding animation */
  animation: loading 1.8s infinite;
  animation-delay: 0.3s;
}
.loading.inclined::after {
  background: linear-gradient(
    110deg,
    transparent,
    transparent,
    rgba(255, 255, 255, 0.763),
    transparent,
    transparent,
    transparent
  );
}

/* Loading Animation */
@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
