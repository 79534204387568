.container {
  width: 415px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}
.inputContainer {
  position: relative;
  width: 100%;
}
.input {
  display: flex;
  width: 100%;
  background-color: var(--bg-third-color);
  /* border: 1px solid var(--border-primary-color); */
  border-radius: 8px;
  padding: 12px 10px;
  gap: 12px;

  outline: none;
  color: var(--text-primary-color);
  font: var(--font-small);
  transition:
    border 0.2s ease,
    box-shadow 0.2s ease;

  padding-right: 75px;
}

.input[type="search"]::-webkit-search-decoration,
.input[type="search"]::-webkit-search-cancel-button,
.input[type="search"]::-webkit-search-results-button,
.input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.input::placeholder {
  color: var(--text-third-color);
}

.input:focus {
  background-color: var(--bg-primary-color);
  padding-right: 45px;
}

.input:not(:focus):not(:disabled):hover {
  background-color: var(--bg-forth-color);
}

.iconGroup {
  display: flex;
  gap: 4px;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  align-items: center;
  justify-content: center;
}
.icon {
  border: 1.5px solid var(--primary-borderdfds-color);
  box-shadow: 0px 1px 2px 0px #0010180d;
  background-color: var(--bg-primary-color);
  padding: 6px;
  color: var(--text-third-color);
  font-size: 12px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.searchBar {
  width: 100%;

  height: 460px;
  overflow-y: auto;
  padding: 0;
}

.searchBar::-webkit-scrollbar {
  width: 12px;
  background: transparent;
}

.searchBar::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 12px;
}
.searchBar:hover::-webkit-scrollbar-thumb {
  background-color: var(--border-primary-color);
}
@media (max-width: 1160px) {
  .container {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .input {
    padding-right: 12px;
  }
}
@media (max-width: 1024px) {
  .iconGroup {
    display: none;
  }
}
