.p {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  color: var(--text-primary-color);
  flex-shrink: 0;
}

.p > span {
  color: var(--text-primary-color);
  font-size: 14px;
  line-height: 19.6px;
}
.span {
  color: var(--text-third-color);
  font-weight: 400;
  font-size: 12px;
  line-height: 16.8px;
}

@media (max-width: 768px) {
  .span {
    display: none;
  }
}
