.footer {
  padding: 24px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  color: var(--secondary-color-400);
  font: var(--font-xs);
}

@media (max-width: 768px) {
  .footer {
    padding: 12px;
  }
}

@media (max-width: 568px) {
  .footer {
    flex-direction: column;
    align-items: start;
  }
}
