.sidebarContainer {
  color: var(--text-primary-color);
}
.sidebar {
  background-color: var(--bg-primary-color);
  height: 100vh;
  width: 310px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  border-right: 1px solid var(--border-primary-color);
  flex-shrink: 0;
  box-shadow: 0 0 12px -2px #0a0a0a14;
  z-index: 1;
  transition: width 0.3s;
}

.sidebar::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 0px;
}

.sidebarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px;
}

.sidebarContentContainer {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.sidebarGenerationTypeBtnsContainer {
  /* border: 1px solid var(--border-primary-color); */
  border-radius: 4px;
  display: flex;
}
.generationTypeBtn,
.toggleTypeBtn {
  background-color: var(--bg-primary-color);
  color: var(--text-primary-color);
  text-align: center;
  padding: 8px;
  width: 50%;
  border: 1px solid var(--border-primary-color);
}
.toggleTypeBtn {
  width: fit-content;
  border-radius: 4px;
}
.generationTypeBtn:first-child {
  border-radius: 4px 0 0 4px;
}
.generationTypeBtn:last-child {
  border-radius: 0 4px 4px 0;
}
.generationTypeBtn.active,
.toggleTypeBtn.active {
  background-color: var(--c-brand-100);
}

@media (max-height: 960px) {
  .content {
    height: 67.3vh;
  }
}
@media (max-height: 870px) {
  .content {
    height: 62.3vh;
  }
}
@media (max-height: 800px) {
  .content {
    height: 63.3vh;
  }
}
@media (max-height: 600px) {
  .content {
    height: 61.3vh;
  }
}

.profileContainer {
  /* position: absolute; */
  margin-top: auto;
  display: block;
  width: 100%;
  padding: 16px;
  padding-top: 0;
}

.profile {
  width: 100%;
  border-radius: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  /* background: #2871ff14; */
  background: var(--bg-secondary-color);
  backdrop-filter: blur(8px);
  border: 1px solid var(--border-primary-color);
}
.closeMobileBtn {
  display: none;
}

@media (max-width: 1160px) {
  .content {
    padding-bottom: 200px;
  }
  .sidebarContainer {
    display: none;
  }
  .sidebarContainer.sidebarOpen {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: #1010183d;
    backdrop-filter: blur(8px);
    z-index: 999;
    right: 0;
    width: 100%;
  }
  .sidebar {
    /* width: 430px; */
    width: 100%;
  }
  .collapseBtn {
    display: none;
  }
  .mobileBackBtn {
    display: flex;
  }
  .closeMobileBtn {
    display: block;
  }

  .sidebarHeader > svg {
    display: none;
  }
}
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
  }

  .sidebarHeader {
    padding: 12px;
  }
}

.mobileBackBtn {
  display: flex;
  border-radius: 8px;
  padding: 8px;
  gap: 6px;
  align-items: center;
}

.mobileBackBtnText {
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
}

.sidebarContainerBoxTitle {
  color: var(--text-primary-color);
  font-weight: 600;
  font-size: 14px;
  line-height: 18.2px;
  padding-bottom: 8px;
}

.sidebarToggleText {
  color: #475467;
  font-weight: 400;
  font-size: 12px;
  line-height: 15.6px;
  text-wrap: nowrap;
}

.sidebarBoxSettings {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.sidebarBoxModal {
  position: relative;
  display: flex;
  background: var(--bg-light-color);
  padding: 6px 16px 6px 12px;
  justify-content: space-between;
  gap: 6px;
  align-items: center;
  cursor: pointer;
  min-height: 40px;
}

.sidebarBoxModal:first-child {
  border-radius: 5px 5px 0 0;
}

.sidebarBoxModal:last-child {
  border-radius: 0 0 5px 5px;
}
.sidebarBoxModal.active {
  cursor: pointer;
}

.sidebarBoxModal.active:hover {
  background: var(--bg-third-color);
}

.sidebarBoxModalActive {
  background: var(--bg-third-color);
}

.sidebarBoxSelect {
  display: flex;
  gap: 4px;
  align-items: center;
}

.sidebarBoxSelect svg path {
  stroke: var(--text-primary-color);
}

.sidebarBoxBtnText {
  color: var(--text-primary-color);
  font-weight: 600;
  font-size: 12px;
  line-height: 16.8px;
}

.sidebarBoxSelectText {
  color: var(--text-third-color);
  font-weight: 400;
  font-size: 12px;
  line-height: 15.6px;
  max-width: 145px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebarBoxDescription {
  background: #f9fafb;
  padding: 6px 16px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
}

.sidebarBoxDescriptionText {
  color: #475467;
  font-weight: 400;
  font-size: 12px;
  line-height: 16.8px;
}

.sidebarBoxDescriptionBtn {
  color: #2871ff;
  font-weight: 500;
  font-size: 12px;
  line-height: 15.6px;
}

.sidebarBoxBtnTitle {
  display: flex;
  align-items: center;
  gap: 6px;
}

.dragDropContainer {
  position: relative;
  height: 170px;
  border: 1px solid var(--border-primary-color);
  border-radius: 4px;
}
.dragDropContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.dragDropBtnsContainer {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dragDropBtnsContainer svg {
  width: 18px;
  height: 18px;
}

.dragDropContainer:hover .dragDropBtnsContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sidebarDurationBtnsContainer {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 6px;
}

.textareaContainer {
  min-height: 78px;
  padding: 2px;
  height: 100%; /* ??? */
}

.sidebarBoxInput {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--border-primary-color);
  border-radius: 5px;
}

.sidebarBoxInput:focus-within {
  box-shadow: var(--focus-brand-500);
}

.textareaPrompt {
  width: 100%;
  height: 100%;
  resize: none;
  padding: 10px 14px;
  color: var(--text-primary-color);
  font-weight: 400;
  font-size: 14px;
  line-height: 18.2px;
  overflow: auto;
  background-color: transparent;
}

.textareaPrompt:focus {
  outline: none;
}

.textareaPrompt::-webkit-scrollbar {
  background-color: transparent;
  border-radius: 12px;
  width: 6px;
}
.textareaPrompt::-webkit-scrollbar-thumb {
  background-color: var(--border-primary-color);
  border-radius: 12px;
  width: 6px;
}
