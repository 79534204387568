.contentWrapper {
  background-color: var(--bg-secondary-color);
  min-height: 100vh;
  box-shadow: 0px 0px 24px -2px #0a0a0a0f;
  border-left: 1px solid var(--c-primary-gray-100);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
}

.content {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
}

@media (max-width: 1160px) {
  .content {
    padding: 0 16px;
  }
}
@media (max-width: 768px) {
  .contentWrapper {
    gap: 12px;
  }
  .content {
    padding: 12px;
    gap: 16px;
  }
}

@media (max-width: 430px) {
  .content {
    padding: 0 12px;
  }
}
