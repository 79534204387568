.link {
  display: flex;
  align-items: center;
  gap: 8px;
  font: var(--font-base);
  text-decoration: none;
}
.link:hover {
  opacity: 0.8;
  text-decoration: none;
}

.link.primary {
  color: var(--text-primary-color);
}

.link.primary path {
  stroke: var(--text-primary-color);
}

.link.inverted_primary {
  color: var(--text-inverted-primary-color);
}
.link.inverted_primary path {
  stroke: var(--text-inverted-primary-color);
}

.brand {
  color: var(--c-brand-500);
}
.brand:hover {
  color: var(--c-brand-600);
}

.secondary {
  color: var(--text-secondary-color);
}
.link.secondary path {
  stroke: var(--text-secondary-color);
}

.border {
  text-decoration: underline;
}

.small {
  font: var(--font-small);
}
