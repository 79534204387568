.app_light_theme {
  --secondary-color-25: #fcfcfd;
  --secondary-color-50: #f9fafb;
  --secondary-color-100: #f2f4f7;
  --secondary-color-200: #eaecf0;
  --secondary-color-300: #d0d5dd;
  --secondary-color-400: #98a1b3;
  --secondary-color-500: #667085;
  --secondary-color-600: #485367;
  --secondary-color-700: #344054;
  --secondary-color-800: #1d2939;
  --secondary-color-900: #101018;
  --secondary-color-950: #0a0a0a;

  --bg-primary-color: white;
  --bg-inverted-primary-color: var(--secondary-color-900);

  --bg-secondary-color: var(--secondary-color-100);
  --bg-light-secondary-color: var(--secondary-color-50);

  --bg-third-color: var(--secondary-color-200);
  --bg-forth-color: var(--secondary-color-300);
  --bg-light-color: var(--secondary-color-50);
  --text-primary-color: var(--secondary-color-900);
  --text-inverted-primary-color: white;
  --text-secondary-color: var(--secondary-color-600);
  --text-third-color: var(--secondary-color-500);
  --text-forth-color: var(--secondary-color-400); /* description color */

  --border-primary-color: var(--secondary-color-200);
  --border-secondary-color: var(--secondary-color-300);

  --sidebar-shadow: 2px 0px 8px 0px #7f89ac1f;

  --c-user-message-bg: var(--c-brand-25);
  --c-assistant-message-bg: var(--secondary-color-50);

  transition: background-color 0.4s;

  --primary-gradient: linear-gradient(
    90deg,
    rgba(0, 132, 255, 0.1) 0%,
    rgba(194, 95, 255, 0.1) 50%,
    rgba(245, 247, 250, 0.1) 100%
  );

  /*---------------------- ТАБЛИЦЫ НАЧАЛО----------------------  */

  /* Основные цвета */
  --theme-background: #ffffff;
  --theme-table-text: #000000;

  /* Таблица */
  --theme-table-box-shadow: rgba(127, 137, 172, 0.15); /* #7F89AC26 */
  --theme-table-head-background: #ffffff;
  --theme-table-header-color: #98a2b3;
  --theme-table-header-border: #d0d5dd;
  --theme-table-row-hover: #f9fafb;
  --theme-table-cell-border: #e0e0e0;
  --theme-table-cell-background: #ffffff;

  /* Статусы */

  --theme-priority-low-color: var(--c-system-success-600);
  --theme-priority-low-background: var(--c-system-success-500);

  --theme-priority-medium-color: #fec84b;
  --theme-priority-medium-background: #fef0c7;

  --theme-priority-high-color: rgba(235, 4, 0, 1);
  --theme-priority-high-background: rgba(235, 4, 0, 0.2);

  --theme-status-closed-bacground: #101018;

  --theme-status-await-color: #fec84b;
  --theme-status-await-background: #fef0c7;

  --theme-status_answered-background: #2871ff;

  /* Круглые элементы */
  --theme-circle-border: #e0e0e0;
  --theme-circle-color: #333333;
  --theme-circle-background: #ffffff;

  /* Кнопки */
  --theme-button-background: #333333;
  --theme-button-hover-background: #555555;
  --theme-button-color: #ffffff;

  /*// ------- CHAT --------- */
  --theme-chat-background: #f5f7fa;
  --theme-chat-background-text-field: rgba(255, 255, 255, 1);
  --theme-chat-border: #ffffff;

  /*---------------------- ТАБЛИЦЫ КОНЕЦ----------------------  */
  --shadow-xsmall: 0px 1px 2px 0px #6670850d;
  --shadow-small: 0px 1px 2px 0px #6670851a;
  --shadow-medium: 0px 0px 8px 0px #7f89ac26;

  --shadow-heavy: 0px 4px 8px 0px #6670851a;
  --shadow-pop-up: 0px 8px 16px 0px #98a2b333;
}
