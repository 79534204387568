.imagesContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.imagesContainerBox {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
}

.pageImagesGridBox {
  border-radius: 8px;
  padding: 24px;
  height: 200px;
  position: relative;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.pageImagesGridBox.disabled {
  cursor: default;
}

.pageImagesGridDesc {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 24px;
  width: 100%;

  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7) 20%,
    rgba(0, 0, 0, 0) 100%
  );
  border-radius: 8px;
}

.pageImagesTitle {
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 19.36px;
}

.pageImagesDesc {
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
}

.pageImagesShow {
  text-align: start;
  color: #98a2b3;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
}

.pageImagesExampleBox {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}

.pageImagesExampleBoxTitle {
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  line-height: 20.8px;
}

.pageImagesExampleContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
}

.pageImagesExampleBoxFilter {
  display: flex;
  align-items: center;
  gap: 12px;
}

.boxFilterBtn {
  background-color: var(--bg-primary-color);
  border: 1px solid #f9fafb;
  border-radius: 4px;
  padding: 10px 12px;
  color: #101018;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
}

.activeBtn {
  background-color: #2871ff;
  color: #ffffff;
}

@media (max-width: 1400px) {
  .imagesContainerBox {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1400px) {
  .bannerContainerBlockText {
    font-size: 28px;
    line-height: 36.6px;
    max-width: 400px;
  }
  .bannerContainerBlock {
    padding: 28px;
    padding-top: 18px;
  }
}
@media (max-width: 768px) {
  .bannerContainerBlock {
    padding: 24px;
    padding-top: 16px;
  }
  .bannerContainerBlockText {
    font-size: 24px;
    line-height: 31.6px;
    max-width: 360px;
  }

  .imagesContainerBox {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 715px) {
  .bannerContainerBlockBtn {
    font: var(--font-small);
    padding: 8px;
  }
}

@media (max-width: 585px) {
  .imagesContainerBox {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 430px) {
  .bannerContainerBlock {
    padding: 16px;
    padding-top: 12px;
  }
  .bannerContainerBlockText {
    font-size: 18px;
    line-height: 24.6px;
  }
}
