.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 24px;
  /* padding-bottom: 24px; */
}
.h1 {
  color: var(--text-primary-color);
  font-weight: 600;
  font-size: 24px;
  line-height: 28.8px;
}

.description {
  color: var(--text-secondary-color);
  font-size: 14px;
  line-height: 19px;
  /* padding-top: 12px; */
}

.balance {
  display: flex;
  align-items: center;
  gap: 8px;
}

.balance span {
  font: var(--font-base);
  color: var(--text-primary-color);
  font-weight: 600;
}

.balance > span {
  font: var(--font-small);
  color: var(--secondary-color-400);
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    padding-bottom: 0;
  }
  .container button {
    width: 100%;
  }
  .h1 {
    font-size: 30px;
  }
  .balance {
    display: none;
  }
}

@media (max-width: 430px) {
  .h1 {
    font-size: 28px;
  }
}
