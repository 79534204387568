.card {
  background-color: var(--bg-primary-color);
  border: 1px solid var(--border-primary-color);
  border-radius: 6px;
  /* padding: 12px 24px 12px 12px; */
  padding: 24px;
  /* width: 100%; */
  color: var(--text-primary-color);
}

.card.hasShadow {
  box-shadow: var(--shadow-medium);
}
.card.isMain {
  padding: 0 24px;
  box-shadow: var(--shadow-heavy);
}

@media (max-width: 768px) {
  .card {
    padding: 16px;
  }
}

@media (max-width: 430px) {
  .card {
    padding: 12px;
  }
}
