.avatarContainer {
  position: relative;
  display: flex;
}
.dropdownMenu {
  width: 275px;
  top: 70px;
}
.dropdownMenu.right {
  right: 0px;
}
.dropdownMenu.left {
  left: 0;
}
.dropdownItem {
  color: var(--text-primary-color);
  padding: 12px;
  /* min-width: 11rem; */
  /* margin: 5%; */
  /* padding-bottom: 15px; Отступ снизу (по желанию) */
  border-bottom: 1px solid var(--border-primary-color); /* Тонкая линия под элементом */
  /* margin-bottom: 10px; Отступ снизу от линии до следующих элементов */
}

.accountName {
  font-weight: 900;
}
.mail {
  font-size: small;
}
.dropdownBalance {
  display: flex;
  gap: 12px;
}

.dropdownItemBtn {
  display: flex;
  flex-direction: column; /* Элементы выстраиваются по колонке */
  padding-top: 12px;
  /* padding: 24px; */
}
.dropdownItemBtn > button,
.dropdownItemBtn > a {
  width: 100%; /* Ширина кнопки на 100% от контейнера */
  padding: 12px; /* Внутренний отступ */
  text-align: left; /* Выравнивание текста в кнопке (по левому краю) */
  font-weight: 600;
  font-size: 14px;
}
.mobileThemeBtn {
  display: none;
}

@media (max-width: 1160px) {
  .dropdownMenu.mobileLeft {
    left: 0;
  }
  .dropdownMenu.mobileRight {
    right: 0;
  }

  .mobileThemeBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;

    border-top: 1px solid var(--border-primary-color);
  }

  .mobileThemeBtn > * {
    flex-shrink: 0;
    flex-grow: 0;
  }
}

@media (max-width: 430px) {
  .dropdownMenu {
    width: 80vw;
  }
}
