/* Файл для переопределения стилей в @blocknote/react редакторе  */

/* .bn-block-content h1:first-of-type {
    font-size: 64px !important;
} */
/* .bn-block-content {
  display: flex;
  padding: 4px 0;
  transition: font-size 0.2s;
  width: 100%;
  margin: 4px 0;
} */
.bn-inline-content p {
  font-size: 14px !important;
  line-height: 24px !important;
  padding: 4px !important;
}
.bn-block p {
  font-size: 14px !important;
  line-height: 24px;
}
/* .bn-block-content h1{
    font-size: 30px !important;
    line-height: 36px;
    margin-top: 24px !important;
    margin-bottom: 24px !important;
} */

/* Первый заголовок h1 это название документа, он по размеру шрифта отличается */
.bn-block-group .bn-block-outer:first-of-type h1 {
  font-size: 32px !important;
  line-height: 48px !important;
  padding-top: 0 !important;
  padding-top: 22px !important;
}

.bn-block-group h1 {
  font-size: 24px !important;
  line-height: 36px !important;
  margin-bottom: 8px !important;
  padding-top: 32px !important;
}

.bn-side-menu[data-block-type="heading"][data-level="1"] {
  padding-top: 40px !important;
  height: 40px !important;
  margin-top: 2px;
}

.bn-block-content h2 {
  font-size: 18px !important;
  line-height: 32px !important;
  padding-bottom: 8px !important;
  padding-top: 24px !important;
}
.bn-side-menu[data-block-type="heading"][data-level="2"] {
  padding-top: 30px !important;
  height: 40px !important;
}

.bn-block-content h3 {
  font-size: 16px !important;
  line-height: 26px !important;
  padding-top: 16px !important;
  margin-bottom: 8px !important;
}

.bn-side-menu[data-block-type="heading"][data-level="3"] {
  padding-top: 18px !important;
  height: 46px !important;
}
