.avatar {
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
  border: 1px solid var(--border-primary-color);
  background-color: var(--bg-secondary-color);
  padding: var(--user-image-padding);
  --user-image-padding: 2.5px;
}

.avatar > svg,
.avatar > img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.large {
  height: calc(60px + var(--user-image-padding));
  width: calc(60px + var(--user-image-padding));
}

.medium {
  height: 50px;
  width: 50px;
}

.small {
  height: calc(40px + var(--user-image-padding));
  width: calc(40px + var(--user-image-padding));
}

.xsmall {
  height: calc(30px + var(--user-image-padding));
  width: calc(30px + var(--user-image-padding));
}
