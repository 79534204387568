.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background: white;
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0 4px 32px rgba(0, 0, 0, 0.08);
    max-width: 480px;
    width: 90%;
    position: relative;
}

.closeIcon {
    position: absolute;
    right: 16px;
    top: 16px;
    background: none;
    border: none;
    font-size: 24px;
    color: #666;
    cursor: pointer;
    padding: 4px;
    line-height: 1;
}

.header {
    margin-bottom: 24px;
}

.titleWrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
}

.titleWrapper h3 {
    font-size: 20px;
    font-weight: 600;
    color: #1A1A1A;
    margin: 0;
}

.discount {
    background: rgba(52, 199, 89, 0.1);
    color: #34C759;
    padding: 4px 8px;
    border-radius: 100px;
    font-size: 14px;
    font-weight: 500;
}

.header h2 {
    font-size: 24px;
    font-weight: 600;
    color: #1A1A1A;
    margin: 0;
    line-height: 1.3;
}

.benefits {
    margin-bottom: 24px;
}

.mainBenefit {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.mainBenefit > span {
    font-size: 16px;
    color: #666;
    margin-right: 12px;
}

.benefitValues {
    display: flex;
    gap: 12px;
    align-items: center;
}

.tokens, .actions {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 18px;
    font-weight: 600;
}

.benefitsList {
    list-style: none;
    padding: 0;
    margin: 0;
}

.benefitsList li {
    padding: 8px 0;
    color: #1A1A1A;
    font-size: 15px;
    display: flex;
    align-items: center;
}

.benefitsList li::before {
    content: '';
    width: 6px;
    height: 6px;
    background: #666;
    border-radius: 50%;
    margin-right: 12px;
    flex-shrink: 0;
}

.aiModels {
    font-size: 14px !important;
    display: flex;
    align-items: center; /* Центрирует элементы по вертикали */
}

.boldText {
    font-weight: 600;
}

.footer {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.actionButton {
    width: 100%;
    max-width: 340px;
    padding: 12px 24px;
    background: linear-gradient(90deg, #E633F9 0%, #0A45EB 100%);
    border: none;
    border-radius: 99px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 16px;
    transition: opacity 0.2s;
    white-space: nowrap;
}

.actionButton:hover {
    opacity: 0.9;
}

.link {
    text-decoration: none;
    font-size: 14px;
}

.link:hover {
    text-decoration: underline;
} 

.timer {
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    background: #105BFF;
    color: white;
    padding: 12px 24px 44px;
    border-radius: 20px 20px 0 0;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    z-index: -1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 480px;
    max-width: 100%; /* Убедитесь, что ширина не превышает 100% */
    height: auto; /* Позвольте высоте адаптироваться */
    margin: -32px 0;
}

.blueText {
    color: #105BFF;
}

/* Отключаем буллеты для элементов с классом noBullet */
.benefitsList li.noBullet::before {
    content: none;
}

/* Контейнер для левой и правой части с разделителем */
.row {
    display: flex;
    align-items: center;
    width: 100%;
}

/* Центр со стрелкой с нужными отступами */
.arrow {
    margin: 0 4px;
}

/* Медиа-запрос для мобильных устройств */
@media (max-width: 480px) {
    .timer {
        font-size: 12px; /* Уменьшите размер шрифта для мобильных */
        padding: 8px 16px; /* Уменьшите отступы */
        height: auto; /* Позвольте высоте адаптироваться */
        margin: -20px 0; /* Уменьшите отрицательные отступы */
    }

    .modalContent {
        padding: 16px; /* Уменьшите внутренние отступы */
    }

    .header h2 {
        font-size: 20px; /* Уменьшите размер заголовка */
    }

    .header .titleWrapper h3 {
        font-size: 18px; /* Уменьшите размер подзаголовка */
    }

    .actionButton {
        padding: 10px; /* Уменьшите отступы */
        font-size: 14px; /* Уменьшите размер текста */
    }

    .discount {
        font-size: 12px; /* Уменьшите размер шрифта для скидки */
    }

    .benefitsList li {
        font-size: 14px; /* Уменьшите размер шрифта для списка */
    }

    .mainBenefit > span {
        font-size: 14px; /* Уменьшите размер текста основного бенефита */
    }
}