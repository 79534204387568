.popupContainer {
  position: absolute;
  z-index: 11;
  background-color: var(--bg-primary-color);
  border-radius: 8px;
  top: 0%;
  left: 310px;
  width: 480px;
  overflow-y: hidden;
  /* max-height: 440px; */
  border: 1px solid var(--border-primary-color);
  box-shadow: 0px 8px 16px 0px #98a2b333;
  padding-bottom: 2px;
  cursor: default;
}
.buttonContainer {
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 0 12px;
  overflow-x: auto;
}
.buttonContainer::-webkit-scrollbar-thumb,
.buttonContainer::-webkit-scrollbar {
  background-color: transparent;
}

.workType {
  padding: 12px 16px;
  font-size: 14px;
  line-height: 21px;
  color: var(--text-primary-color);
  font-weight: 500;
}

.toggle {
  border-radius: 999px;
  padding: 4px 10px;
  border: 1px solid var(--border-primary-color);
  font-size: 14px;
  align-items: 21px;
  display: flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  width: fit-content;
}
.toggle svg {
  width: 16px;
  height: 16px;
}
.toggle.activeToggle {
  background-color: var(--c-brand-100);
  border-color: transparent;
}
.popupContainerHeaderTitle {
  color: #101018;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.6px;
}

.popupContainerBox {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.popupContainerBoxText {
  padding: 12px 16px;
  color: #667085;
  font-weight: 400;
  font-size: 12px;
  line-height: 16.8px;
}

.modalBlock {
  display: flex;
  padding: 8px 16px;
  gap: 12px;
  align-items: start;
  justify-content: space-between;
  cursor: pointer;
}

.modalBlockActive {
  background: #f2f4f7;
}

.modalBlock:hover {
  background: #f2f4f7;
}

.modalBlockText {
  display: flex;
  flex-direction: column;
}

.modalBlockTitle {
  color: #101018;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
}

.modalBlockDescription {
  color: #98a2b3;
  font-weight: 400;
  font-size: 11px;
  line-height: 15.4px;
  text-wrap: wrap;
}

.modalBlockToken {
  display: flex;
  gap: 2px;
  align-items: center;
}

.modalBlockParam {
  display: flex;
  gap: 12px;
  align-items: start;
}

.modalBlockTokenText {
  color: #667085;
  font-weight: 500;
  font-size: 12px;
  line-height: 16.8px;
}

@media (max-width: 1160px) {
  .popupContainer {
    top: 48px;
  }
}

@media (max-width: 820px) {
  .popupContainer {
    left: 200px;
  }
}

@media (max-width: 768px) {
  .popupContainer {
    right: 0px;
    left: auto;
    max-width: 100%;
  }
}
