.fullWrapper {
  height: 100vh;
  width: 100vw;
  padding-top: 40vh;
  /* background-color: var(--bg-secondary-color); */
}
.wrapper {
  padding: 20vh 0;
  background-color: transparent;
}

.preloaderContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.preloader {
  width: cover;
  display: block;
}
