.app_dark_theme {
  --inverted-bg-color: white;
  --secondary-color-25: #fcfcfd;
  --secondary-color-50: #f9fafb;
  --secondary-color-100: #f3f4f6;
  --secondary-color-200: #eaeaea;
  --secondary-color-300: #d0d5dd;
  --secondary-color-400: #999999;
  --secondary-color-500: #020101;
  --secondary-color-600: #2b2b2b;
  --secondary-color-700: #212121;
  --secondary-color-800: #191919;
  --secondary-color-900: #141414;
  --secondary-color-950: #0a0a0a;

  --bg-primary-color: var(--secondary-color-800);
  --bg-inverted-primary-color: white;
  --bg-secondary-color: var(--secondary-color-950);
  --bg-light-secondary-color: var(--secondary-color-800);

  --bg-third-color: var(--secondary-color-900);
  --bg-forth-color: var(--secondary-color-800);
  --bg-light-color: var(--secondary-color-700);

  --text-primary-color: white;
  --text-inverted-primary-color: var(--secondary-color-900);
  --text-secondary-color: var(--secondary-color-300);
  --text-third-color: var(--secondary-color-400);
  --text-forth-color: var(--secondary-color-400);

  --border-primary-color: var(--secondary-color-600);
  --border-secondary-color: var(--secondary-color-500);

  --primary-gradient: linear-gradient(
    90deg,
    rgba(0, 132, 255, 0.4) 0%,
    rgba(194, 95, 255, 0.4) 50%,
    rgba(27, 27, 27, 0.4) 100%
  );

  --c-user-message-bg: var(--secondary-color-700);
  --c-assistant-message-bg: var(--secondary-color-700);
  --c-brand-50: #203053;
  /*---------------------- ТАБЛИЦЫ НАЧАЛО----------------------  */
  /* Основные цвета */
  --theme-background: #191919;

  --theme-table-text: #ffffff;

  /* Таблица */
  --theme-table-box-shadow: rgba(
    127,
    137,
    172,
    0.15
  ); /* Можно оставить тот же цвет или изменить при необходимости */
  --theme-table-head-background: #1f1f1f;
  --theme-table-header-color: #a1a1a1;
  --theme-table-header-border: #3a3a3a;
  --theme-table-row-hover: #2a2a2a;
  --theme-table-cell-border: #3a3a3a;
  --theme-table-cell-background: #1e1e1e;

  /* Поддержка ID */
  --theme-support-id-color: #ffffff;
  --theme-support-id-background: #333333;

  /* Статусы */
  --theme-priority-low-color: var(--c-system-success-600);
  --theme-priority-low-background: var(--c-system-success-500);

  --theme-priority-medium-color: #fec84b;
  --theme-priority-medium-background: #fef0c7;

  --theme-priority-high-color: rgba(235, 4, 0, 1);
  --theme-priority-high-background: rgba(235, 4, 0, 0.2);

  --theme-status-closed-bacground: #101018;

  --theme-status-await-color: #fec84b;
  --theme-status-await-background: #fef0c7;

  --theme-status_answered-background: #2871ff;

  /* Круглые элементы */
  --theme-circle-border: #3a3a3a;
  --theme-circle-color: #ffffff;
  --theme-circle-background: #191919;

  /* Кнопки */
  --theme-button-background: #444444;
  --theme-button-hover-background: #666666;
  --theme-button-color: #ffffff;

  /*---------------------- ТАБЛИЦЫ КОНЕЦ----------------------  */

  /*// ------- CHAT --------- */
  --theme-chat-background: rgb(34, 34, 34);
  --theme-chat-background-text-field: rgb(34, 34, 34);
  --theme-chat-border: #4e4e4e;

  --shadow-medium: 0px 0px 8px 0px #40404026;
}
