.container {
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.container:not(:disabled):hover {
  transform: translateY(-4px);
}

.default {
  width: 40px;
  height: 40px;
}

.medium {
  width: 32px;
  height: 32px;
}

.white,
.shadowWhite {
  border: 1px solid var(--border-primary-color);
  background-color: var(--bg-primary-color);
}
.white svg path,
.shadowWhite svg path {
  stroke: var(--text-primary-color);
}
.white:hover,
.shadowWhite::not(:disabled):hover {
  border-color: var(--border-secondary-color);
}

.shadowWhite {
  background-color: var(--bg-primary-color);
  box-shadow: 0px 2px 8px 0px #7f89ac33;
}

.blue {
  border: 1px solid var(--c-brand-500);
  background-color: var(--c-brand-500);
}
.blue svg path {
  stroke: white;
}
.blue:hover {
  background-color: var(--c-brand-600);
}
.blue:active,
.blue.actived {
  background-color: var(--c-brand-700);
}
.container.disabled svg {
  opacity: 0.2;
}
