:root {
  --c-brand-25: #f6f8ff;
  --c-brand-50: #eff4ff;
  --c-brand-100: #d1dfff;
  --c-brand-200: #b2ccff;
  --c-brand-300: #83adff;
  --c-brand-400: #538aff;
  --c-brand-500: #2871ff;
  --c-brand-600: #105bff;
  --c-brand-700: #0a45eb;
  --c-brand-800: #0040c1;
  --c-brand-900: #01359e;

  --c-secondary-brand-25: #fafaff;
  --c-secondary-brand-50: #f4f3ff;
  --c-secondary-brand-100: #eae9ff;
  --c-secondary-brand-200: #dad6fe;
  --c-secondary-brand-300: #beb5fe;
  --c-secondary-brand-400: #9b89fb;
  --c-secondary-brand-500: #7a5bf9;
  --c-secondary-brand-600: #6938ef;
  --c-secondary-brand-700: #5825dd;
  --c-secondary-brand-800: #491fb8;
  --c-secondary-brand-900: #3d1c96;

  --c-system-error-25: #fffbfa;
  --c-system-error-50: #fef3f2;
  --c-system-error-100: #fee4e2;
  --c-system-error-200: #fecdca;
  --c-system-error-300: #fda29b;
  --c-system-error-400: #f97066;
  --c-system-error-500: #f04438;
  --c-system-error-600: #d92d20;
  --c-system-error-700: #b42318;
  --c-system-error-800: #912018;
  --c-system-error-900: #7a271a;
  --c-system-error-950: #55160c;

  --c-system-warning-25: #fffcf5;
  --c-system-warning-50: #fffaeb;
  --c-system-warning-100: #fef0c7;
  --c-system-warning-200: #fedf89;
  --c-system-warning-300: #fec84b;
  --c-system-warning-400: #fdb022;
  --c-system-warning-500: #f79009;
  --c-system-warning-600: #dc6803;
  --c-system-warning-700: #b54708;
  --c-system-warning-800: #93370d;
  --c-system-warning-900: #7a2e0e;
  --c-system-warning-950: #4e1d09;

  --gradient-25: linear-gradient(180deg, #fcfcfd 0%, #f3f4f6 100%);
  --gradient-300: linear-gradient(180deg, #d2d6dc 0%, #f3f4f6 100%);
  --gradient-400: linear-gradient(180deg, #9ea3ae 0%, #e5e7ea 100%);
  --gradient-500: linear-gradient(180deg, #4d5761 0%, #9ea3ae 100%);
  --gradient-600: linear-gradient(180deg, #1f2a37 0%, #4d5761 100%);

  --c-system-success-25: #f7fef9;
  --c-system-success-50: #ecfdf3;
  --c-system-success-100: #dcfae6;
  --c-system-success-200: #abefc6;
  --c-system-success-300: #75e0a7;
  --c-system-success-400: #47cd89;
  --c-system-success-500: #17b26a;
  --c-system-success-600: #079455;
  --c-system-success-700: #067647;
  --c-system-success-800: #085d3a;
  --c-system-success-900: #074d31;
  --c-system-success-950: #053321;

  --focus-brand-500: 0px 0px 0px 4px #006cef33, 0px 1px 2px 0px #0010180d;

  --focus-secondary-brand-500: 0px 0px 0px 4px #7a5bf933,
    0px 1px 2px 0px #0010180d;
  --focus-secondary-500: 0px 0px 0px 4px #66708533, 0px 1px 2px 0px #0010180d;
  --focus-error-500: 0px 0px 0px 4px #f0443833, 0px 1px 2px 0px #0010180d;

  --modal-z-index: 999;

  --font-family: "Inter", sans-serif;
  --font-size-xsmall: 12px;
  --line-height-xsmall: 16.8px;
  --font-xs: var(--font-size-xsmall) / var(--line-height-xsmall)
    var(--font-family);

  --font-size-small: 14px;
  --line-height-small: 19.6px;
  --font-small: var(--font-size-small) / var(--line-height-small)
    var(--font-family);

  --font-size-base: 16px;
  --line-height-base: 22.4px;
  --font-base: var(--font-size-base) / var(--line-height-base)
    var(--font-family);

  --font-size-m: 18px;
}
