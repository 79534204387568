.root {

  .blockSelect {
    display: flex;
    gap: 24px;
  }

  // .textField {
  //   //height: 44px;
  // }

  .textArea {
    width: 100%;
    background-color: var(--bg-primary-color);
    border: 1px solid var(--border-primary-color);
    border-radius: 12px;
    color: var(--text-primary-color);
    padding: 18px 16px;
    height: 192px;
    box-shadow: 0 1px 1px -1px #0000000d;
    transition: box-shadow 0.2s ease;
    font-size: 16px;
    line-height: 24px;
    scrollbar-width: thin;
    resize: none;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .textArea:focus,
  .textArea:active {
    outline: none;
    border: 1px solid var(--c-brand-500);
    box-shadow: var(--focus-brand-500);
  }

  .textArea::placeholder {
    color: var(--secondary-color-400);
  }
   @media (max-width: 530px) {
    .blockSelect {
    flex-direction: column;
    }
  }

  @media (max-width: 430px) {
    .textArea {
      padding: 12px;
      border-radius: 8px;
    }
  }
}

.label {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-third-color);
  margin-top: 0;
  margin-bottom: 19px;
}

.errorMsg {
  color: red;
  font-size: 14px;
}
