.modalContainer {
  position: fixed;
  z-index: var(--modal-z-index);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 100vw; */
  height: 100%;
  width: 100%;
  padding: 40px;
  /* min-height: 100vh; */
  display: flex;
  background: #1010183d;

  backdrop-filter: blur(12px);
  overflow: auto;

  opacity: 0;
  visibility: hidden;
  /* pointer-events: none; */
  transition:
    opacity 0.3s ease,
    visibility 0s 0.3s;
}
.modalContainer.open {
  opacity: 1;
  visibility: visible;

  transition: opacity 0.3s ease;
}

.animated {
  /* position: relative; */
  display: flex;
  justify-content: center;
  /* height: 100%; */
  width: 100%;
  margin: auto;
  transform: scale(0.7);
  opacity: 0;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
  pointer-events: none;
}
.animatedOpen {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}
.modalContainer > div {
  margin: auto;
}
@media (max-width: 768px) {
  .modalContainer {
    padding: 15px;
  }
  .modalContainer > div {
    width: 100%;
  }
}
