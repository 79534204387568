.bannerContainer {
  border-radius: 8px;
  position: relative;
}

.bannerContainerImg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  object-fit: cover;
  border-radius: 8px;
}
.bannerContainerBlock {
  position: relative;
  padding: 24px 32px 32px 32px;
  border-radius: 8px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0.004) 51.33%,
      rgba(0, 0, 0, 0.008) 52.66%,
      rgba(0, 0, 0, 0.02) 53.99%,
      rgba(0, 0, 0, 0.04) 55.31%,
      rgba(0, 0, 0, 0.06) 56.64%,
      rgba(0, 0, 0, 0.086) 57.97%,
      rgba(0, 0, 0, 0.118) 59.3%,
      rgba(0, 0, 0, 0.153) 60.63%,
      rgba(0, 0, 0, 0.192) 61.96%,
      rgba(0, 0, 0, 0.24) 63.28%,
      rgba(0, 0, 0, 0.286) 64.61%,
      rgba(0, 0, 0, 0.34) 65.94%,
      rgba(0, 0, 0, 0.4) 67.27%,
      rgba(0, 0, 0, 0.467) 68.6%,
      rgba(0, 0, 0, 0.533) 69.93%,
      rgba(0, 0, 0, 0.6) 71.25%,
      rgba(0, 0, 0, 0.66) 72.58%,
      rgba(0, 0, 0, 0.714) 73.91%,
      rgba(0, 0, 0, 0.76) 75.24%,
      rgba(0, 0, 0, 0.808) 76.57%,
      rgba(0, 0, 0, 0.847) 77.9%,
      rgba(0, 0, 0, 0.882) 79.23%,
      rgba(0, 0, 0, 0.914) 80.55%,
      rgba(0, 0, 0, 0.94) 81.88%,
      rgba(0, 0, 0, 0.96) 83.21%,
      rgba(0, 0, 0, 0.98) 84.54%,
      rgba(0, 0, 0, 0.992) 85.87%,
      rgba(0, 0, 0, 0.996) 87.2%,
      #000000 88.52%
    );

  position: relative;
  z-index: 20;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.bannerContainerBlockText {
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
  line-height: 31.6px;
  width: 560px;
}

.bannerContainerBlockBtn {
  font-weight: 700;
  font-size: 14px;
}

.bannerContainerBlockClose {
  position: absolute;
  top: 12px;
  right: 12px;
}
.bannerContainerBlockClose svg path {
  stroke: #ffffff;
}
