.header {
  padding: 24px 70px;
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@media (max-width: 1440px) {
  .header {
    padding: 24px 70px;
  }
}
@media (max-width: 768px) {
  .header {
    padding: 24px;
  }
}

@media (max-width: 430px) {
  .header {
    padding: 24px 16px;
  }
}
