.dropdown {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  &__button {
    border: 1px solid var(--border-primary-color);
    border-radius: 8px;
    padding: 6px 12px;
    box-shadow: var(--shadow-xsmall);
    width: 100%;
    color: var(--text-primary-color);
    font-weight: 400;
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 44px;
    cursor: pointer;
    background-color: var(--bg-primary-color);

    &:hover {
      //background-color: #F5F5F5;
    }
  }

  &__content {
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid var(--border-primary-color);
    border-radius: 8px;
    padding: 10px 10px;
    box-shadow: var(--shadow-xsmall);
    width: 100%;
    background-color: var(--bg-primary-color);
    max-height: 200px;
    overflow-y: auto;
    z-index: 100;
    color: var(--text-primary-color);
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__item {
      padding: 8px 12px;
      color: var(--text-primary-color);
      font-weight: 400;
      font-size: var(--font-size-base);
      line-height: var(--line-height-base);
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}
