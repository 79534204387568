/* Размеры колонок */
[class*="col-"] {
  flex: 0 0 auto;
  width: 100%;
} /* Колонки для размера xxl */
@media (min-width: 1400px) {
  .col-xxl-1 {
    flex: 0 0 8.33%;
    max-width: 8.33%;
  }
  .col-xxl-2 {
    flex: 0 0 16.67%;
    max-width: 16.67%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .col-xxl-5 {
    flex: 0 0 41.67%;
    max-width: 41.67%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .col-xxl-8 {
    flex: 0 0 66.67%;
    max-width: 66.67%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }
  .col-xxl-11 {
    flex: 0 0 91.67%;
    max-width: 91.67%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
} /* Колонки для размера xl */
@media (min-width: 1200px) and (max-width: 1399px) {
  .col-xl-1 {
    flex: 0 0 8.33%;
    max-width: 8.33%;
  }
  .col-xl-2 {
    flex: 0 0 16.67%;
    max-width: 16.67%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .col-xl-5 {
    flex: 0 0 41.67%;
    max-width: 41.67%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .col-xl-8 {
    flex: 0 0 66.67%;
    max-width: 66.67%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }
  .col-xl-11 {
    flex: 0 0 91.67%;
    max-width: 91.67%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
} /* Колонки для размера lg */
@media (min-width: 992px) and (max-width: 1199px) {
  .col-lg-1 {
    flex: 0 0 8.33%;
    max-width: 8.33%;
  }
  .col-lg-2 {
    flex: 0 0 16.67%;
    max-width: 16.67%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .col-lg-5 {
    flex: 0 0 41.67%;
    max-width: 41.67%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .col-lg-8 {
    flex: 0 0 66.67%;
    max-width: 66.67%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }
  .col-lg-11 {
    flex: 0 0 91.67%;
    max-width: 91.67%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
} /* Колонки для размера md */
@media (min-width: 768px) and (max-width: 991px) {
  .col-md-1 {
    flex: 0 0 8.33%;
    max-width: 8.33%;
  }
  .col-md-2 {
    flex: 0 0 16.67%;
    max-width: 16.67%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .col-md-5 {
    flex: 0 0 41.67%;
    max-width: 41.67%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .col-md-8 {
    flex: 0 0 66.67%;
    max-width: 66.67%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }
  .col-md-11 {
    flex: 0 0 91.67%;
    max-width: 91.67%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
} /* Колонки для размера sm */
@media (min-width: 576px) and (max-width: 767px) {
  .col-sm-1 {
    flex: 0 0 8.33%;
    max-width: 8.33%;
  }
  .col-sm-2 {
    flex: 0 0 16.67%;
    max-width: 16.67%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .col-sm-5 {
    flex: 0 0 41.67%;
    max-width: 41.67%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .col-sm-8 {
    flex: 0 0 66.67%;
    max-width: 66.67%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }
  .col-sm-11 {
    flex: 0 0 91.67%;
    max-width: 91.67%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
} /* Колонки для размера xs */
@media (max-width: 575.98px) {
  .col-1 {
    flex: 0 0 8.33%;
    max-width: 8.33%;
  }
  .col-2 {
    flex: 0 0 16.67%;
    max-width: 16.67%;
  }
  .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .col-5 {
    flex: 0 0 41.67%;
    max-width: 41.67%;
  }
  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-7 {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .col-8 {
    flex: 0 0 66.67%;
    max-width: 66.67%;
  }
  .col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-10 {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }
  .col-11 {
    flex: 0 0 91.67%;
    max-width: 91.67%;
  }
  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
