.container {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 100%;
  gap: 8px;
}
.label {
  padding-left: 8px;
}
.smallGap {
  gap: 6px;
}

.inputContainer {
  display: flex;
  align-items: center;
  border: 1px solid var(--border-primary-color);
  background: var(--bg-primary-color);
  border-radius: 4px;
  width: 100%; /*28vw;*/
  box-shadow: 0px 1px 1px -1px #0000000d;
  transition:
    /* border-color 0.2s ease, */ box-shadow 0.2s ease;
}
.inputContainer > button {
  transform: none !important;
}

.input {
  width: 100%;
  background-color: inherit;
  border: 1px solid transparent;
  border-radius: 4px;
  color: var(--text-primary-color);
}
.inputContainer.large,
.inputContainer.medium {
  gap: 12px;
}

.input.large {
  padding: 18px 16px;
}

.input.medium {
  padding: 6px 12px;
  font: var(--font-small);
  min-height: 40px;
}

.input.small {
  padding: 8px;
  font: var(--font-small);
}

.inputContainer.active {
  border: 1px solid var(--c-brand-500);
  outline: none;
  box-shadow: var(--focus-brand-500);
}

.inputContainer.disabled {
  background-color: var(--secondary-color-50);
  color: var(--c-system-error-400);
}

.label,
.input,
.input::placeholder,
.invalidText.large,
.description.large {
  font: var(--font-small);
}

.invalidText.medium,
.description.medium {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.input:focus,
.input:active {
  outline: none;
}

.label,
.input {
  color: var(--text-third-color);
}

.input::placeholder,
.description {
  color: var(--secondary-color-400);
  margin: 0;
}

.invalid {
  border: 1px solid var(--c-system-error-200);
  box-shadow: 0px 2px 1px -1px #0000000d;
}
.invalid > input,
.invalid::placeholder,
.invalidText {
  color: var(--c-system-error-500);
}

.invalid:focus-within,
.invalid:active:not(.disabled) {
  border: 1px solid var(--c-system-error-400);
  outline: none;
  box-shadow: var(--box-shadow-error);
}

.inputContainer.disabled.invalid {
  background-color: var(--c-system-error-25);
}

.eyeButton {
  margin-right: 8px;
}

.squared {
  border-radius: 2px;
}

.gray {
  background-color: var(--secondary-color-50);
}
.dark_gray {
  background-color: var(--secondary-color-200);
}

@media (max-width: 430px) {
  .input,
  .label,
  .input::placeholder,
  .invalidText {
    font-size: 16px;
    line-height: 24px;
  }

  .inputContainer {
    border-radius: 8px;
    gap: 8px;
  }

  .input {
    padding: 12px;
    border-radius: 8px;
  }

  .eyeButton {
    padding: 8px 8px;
  }
}

.input:focus-visible,
.input:active,
.input:focus {
  box-shadow: none !important;
}
