.form-upload {
  display: grid;
  align-items: center;
  width: 80vw;
  min-width: 360px;
}

.uploadZoneDragover {
  position: relative;
  background-color: var(--bg-light-secondary-color);
  border-radius: 5px;
  border: 1px solid var(--border-primary-color);
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.uploadZoneDragover:hover {
  background-color: var(--bg-secondary-color);
}

.uploadZoneDragover:hover svg path {
  stroke: var(--c-brand-500);
}

.mainText,
.uploadZoneDragover.active .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  justify-content: center;
}
.uploadZoneDragover.active .mainText {
  filter: blur(5px);
}
.uploadZoneDragover.active .text {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  z-index: 1;
  background: #10101899;
  color: white;
  border-radius: 12px;
}
.text {
  display: none;
}

.uploadZoneDragover .upload-zone_dragover {
  display: grid;
  height: 50vh;
  min-height: 360px;
  margin-bottom: 25px;
  border: 1px solid currentColor;
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  place-content: center;
  text-align: center;
}

.uploadZoneDragover svg {
  width: 10vw;
  margin: auto;
  pointer-events: none;
}

.form-upload__hint {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
}

.uuploadZoneDragover._active {
  color: #c56fff;
  background-color: #c56fff77;
}

.formUploadLabel {
  display: flex;
  justify-content: center;
  width: fit-content;
  color: white;
  align-items: center;
  background-color: var(--c-brand-500);
  border-radius: 12px;
  padding: 12px 24px;
  cursor: pointer;
  margin-bottom: 8px;
}
.formUploadLabel input {
  display: none;
}

.form-upload__title {
  margin-right: 55px;
  font-size: 18px;
  font-weight: 500;
}

.form-upload__input {
  font-family: inherit;
  font-size: 18px;
}

.form-upload__input::file-selector-button {
  margin-right: 30px;
  border: none;
  border-radius: 6px;
  padding: 9px 15px;
  font-family: inherit;
  font-weight: inherit;
  transition: background-color 0.2s linear;
  cursor: pointer;
}

.form-upload__input::file-selector-button:hover {
  background-color: #c56fff;
}

.form-upload__container {
  width: 360px;
  margin-top: 10px;
  font-size: 16px;
}

.upload-zone_dragover {
  background-color: #593273;
}

.upload-hint,
.upload-status {
  width: 75%;
}

.upload-hint {
  display: none;
}

.upload-hint_visible {
  display: block;
  pointer-events: none;
}

.upload-loader {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.upload-loader_visible {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #593273;
}

.upload-loader__image {
  width: 150px;
  height: 150px;
}

@media (max-width: 768px) {
  .upload-zone {
    padding: 55px 30px;
  }

  .form-upload__title {
    display: block;
    margin-right: 0;
  }

  .form-upload__input::file-selector-button {
    min-width: initial;
    margin-right: 10px;
  }
}

.dragDropContainer {
  position: relative;
  height: 170px;
  border: 1px solid var(--border-primary-color);
  border-radius: 4px;
}
.dragDropContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.dragDropBtnsContainer {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dragDropBtnsContainer svg {
  width: 18px;
  height: 18px;
}

.dragDropContainer:hover .dragDropBtnsContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.retry {
  display: block;
  cursor: pointer;
  background-color: var(--bg-primary-color);
  padding: 7px 8px;
  border-radius: 4px;
}

.retry:hover {
  background-color: var(--bg-secondary-color);
}
