.container {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: var(--bg-primary-color);
  box-shadow: 0px 8px 16px 0px #98a2b333;
  width: 600px;
}
.info {
  padding: 24px;
  display: flex;
  border-radius: 16px 16px 0 0;
  flex-direction: column;
  gap: 16px;
  background: linear-gradient(
    165.75deg,
    rgba(181, 145, 233, 0.24) 0%,
    rgba(205, 241, 255, 0.24) 28%,
    rgba(132, 137, 245, 0.24) 60%,
    rgba(205, 160, 255, 0.24) 72%,
    rgba(204, 232, 254, 0.24) 100%
  );
}
.description {
  color: var(--text-secondary-color);
}
.content {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.btnsContainer {
  display: flex;
  gap: 12px;
  width: 100%;
}

.price,
.pricePreferred {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  border: 1px solid var(--border-primary-color);
  border-radius: 12px;
  padding: 12px 16px;
  width: 100%;
}
.pricePreferred {
  border: 1px solid rgb(177, 83, 170);
}
.prevDescription {
  color: var(--text-forth-color);
  text-decoration: line-through;
}
.descriptionPreferred {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(
    90deg,
    #0084ff 0%,
    #c974fe 75%,
    #c974fe 100%
  );
}
.pricesContainer {
  display: flex;
  gap: 12px;
}

@media (max-width: 500px) {
  .pricesContainer {
    flex-direction: column;
  }
}

@media (max-width: 560px) {
  .btnsContainer {
    flex-direction: column;
  }
}
