.sidebarContainer {
  position: relative;
}
.sidebar {
  position: relative;
  background-color: var(--bg-primary-color);
  position: relative;
  height: 100vh;
  width: 272px;
  display: flex;
  flex-direction: column;

  flex-shrink: 0;

  box-shadow: var(--sidebar-shadow);

  transition: width 0.3s;
}
.sidebar.collapsed {
  width: 72px;
}
.linkTitle {
  transition:
    opacity 0.3s,
    visibility 0.3s,
    width 0.3s;
  opacity: 1;
  visibility: visible;
  width: fit-content;
  /* font: var(--font-small); */
  font-size: 14px;
  line-height: 19.6px;
  text-wrap: nowrap;
  font-weight: 400;
}

.sidebar.collapsed .navLink {
  gap: 0;
  justify-content: center;
}
.sidebar.collapsed .linkTitle {
  opacity: 0;
  visibility: hidden;
  width: 0;
  display: none;
}
.sidebar.collapsed .profileContainer,
.sidebar.collapsed .soon {
  display: none;
}
.sidebarHeader {
  display: flex;
  align-items: center;
  padding: 24px;
  position: relative;
  border-bottom: 1.5px solid transparent;
}

.sidebar.collapsed .sidebarHeader {
  padding: 24px 0;
  justify-content: center;
}
.sidebar.collapsed .sidebarHeader svg {
  flex-shrink: 0;
}

.navItem {
  width: 100%;
  max-height: 40px;
}

.navLink {
  padding: 10px 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-primary-color);
  text-decoration: none;
  cursor: default;
}
.navLink.disabled {
  padding: 10px 24px;
}

.navLink path {
  stroke: var(--secondary-color-400);
}
.navLink:not(.disabled):hover,
.active {
  background: var(--primary-gradient);
  cursor: pointer;
}
.navLink.disabled .linkTitle {
  color: var(--text-forth-color);
  width: 137px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  opacity: 0.5;
}
.navLink.disabled path {
  stroke: var(--text-forth-color);
}
.navLink svg {
  flex-shrink: 0;
}

.navLink.disabled svg {
  opacity: 0.5;
}

.navLink.active svg,
.navLink.active path,
.navLink.active rect,
.navLink.active circle {
  stroke: var(--text-primary-color);
  font-weight: 500;
}
.soon {
  border: 1px solid var(--border-primary-color);
  border-radius: 2px;
  padding: 6px;
  margin-left: auto;
  color: var(--text-third-color);
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  text-transform: uppercase;
  /* max-height: 24px; */
}
.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-grow: 1;
}
.navList {
  /* border: 1px solid red; */
  height: 92vh;
  padding-bottom: 240px;
  overflow-y: auto;
}
.navList::-webkit-scrollbar {
  display: none;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  height: 100%;
}

.navTitle {
  padding: 0 16px;
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--c-primary-gray-400);
}

.sidebarFooter {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.collapseBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -12px;
  background-color: var(--bg-primary-color);
  border-radius: 999px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border: 1px solid var(--border-primary-color);
}

.navLink path {
  stroke: var(--secondary-color-400);
}

.profileContainer {
  padding: 12px;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 12px;
  gap: 12px;
  background: var(--bg-secondary-color);
  border: 1px solid var(--border-primary-color);
  color: var(--text-primary-color);
}

.title {
  font: var(--font-base);
  line-height: 19.6px;
  font-weight: 500;
  max-width: 170px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--text-primary-color);
}

.titleBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 4px;
  gap: 12px;
}

.profileContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.profileImg {
  border-radius: 999px;
  width: 50px;
  height: 50px;
  background-color: var(--c-primary-gray-300);
}
.mobileCloseBtn {
  display: none !important;
}

.avatarContainer {
  display: flex;
  align-items: center;
  gap: 6px;
}

.buttonPlane {
  max-height: 32px;
}

@media (max-width: 1160px) {
  .sidebarContainer {
    display: none;
  }
  .navLink.disabled .linkTitle {
    width: auto;
  }
  .sidebarContainer.sidebarOpen {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    /* right: 0, */
    background: #1010183d;
    backdrop-filter: blur(8px);
    z-index: 999;
    right: 0;
    width: 100%;
  }
  .sidebar {
    width: 430px;
  }

  .collapseBtn {
    display: none;
  }
  /* .sidebar {
    display: block;
    position: absolute;
    z-index: 99;
    width: 430px;
  } */
  .mobileCloseBtn {
    display: block !important;
    margin-left: auto;
  }

  .profile {
    background-color: var(--c-brand-50);
    align-items: center;
  }
  .avatarContainer {
    flex-direction: column;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .sidebarContainer {
    display: none;
  }
  .sidebarContainer.sidebarOpen {
    display: block;
    position: absolute;
    z-index: 99;
    width: 100%;
  }

  .collapseBtn {
    display: none;
  }
  .sidebar {
    width: 100%;
  }
  /* .sidebar {
    display: block;
    position: absolute;
    z-index: 99;
    width: 100%;
  } */
  .mobileCloseBtn {
    display: block !important;
    margin-left: auto;
  }

  .profile {
    background-color: var(--c-brand-50);
    align-items: center;
  }
  .avatarContainer {
    flex-direction: column;
    text-align: center;
  }
  .sidebarHeader {
    height: 11vh;
  }
  .navList {
    height: 89vh;
  }
}

@media (max-height: 768px) {
  .navList {
    padding-bottom: 250px;
    height: 89vh;
  }
  .sidebarHeader {
    height: 11vh;
  }
}
