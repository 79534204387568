@tailwind base;
@tailwind components;
@tailwind utilities;

th,
td {
  border: 1px solid rgb(192, 187, 187);
  padding: 12px;
  text-align: center;
}
.yaPreloadingSuggestBlockContainer {
  z-index: 999;
}
