.container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  .title {
    font-size: 14px;
    font-weight: 500;
    color: rgba(160, 166, 177, 1);
  }
}
