.container {
  background-color: var(--bg-primary-color);
  border-radius: 12px;
  // height: 880px;
  .flexContainer {
    display: flex;


    .descriptionBlock {
      width: 321px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      //height: 100%;
      .blockDescr {
        .textHeader {
          text-transform: uppercase;
          color: rgba(152, 162, 179, 1);
          font-size: 12px;
          font-weight: 500;
        }
        .textDescr {
          font-weight: 400;
          font-size: 16px;
          color: var(--text-primary-color);
        }
        .delimiter {
          height: 2px;
          width: 100%;
          background: rgba(242, 244, 247, 1);
          margin: 24px 0;
        }
      }

      .btnReqRes {
        background-color: rgba(249, 250, 251, 1);
        color: var(--text-primary-color);
        width: fit-content;
        border: 1px solid rgba(249, 250, 251, 1);
      }
    }

    .chatBlock {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      background: var(--theme-chat-background);
      width: 100%;
      height: 100%;
    }
  }
}

.modalReq {
  max-width: 650px;
  width: 100%;
  height: 300px;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 24px;
  .headerModal {
    display: flex;
    justify-content: space-between;
    .textModalHeader {
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
      color: var(--text-primary-color);
    }
    .modalBtn {
      background-color: transparent;
      width: 24px;
      height: 24px;
      border: none;
    }
  }
  .modalContent {
    height: 68%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .textContent {
      font-size: 18px;
      color: var(--text-primary-color);
      font-weight: 600;
      margin: 0;
    }
    .textContentDescr {
      font-size: 16px;
      font-weight: 400;
      color: rgba(152, 162, 179, 1);
    }
  }
  .modalBtnContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 12px;
    .btnModalArchive {
      width: 100%;
    }
    .btnModalCancel {
      width: 100%;
    }
  }
}

.mobileBtnGroup {
  display: none;
}

@media (max-width: 1160px) {
  .flexContainer {
  position: relative;
  }
  .descriptionBlock {
    // border-radius: 12px;
  
    background-color: var(--bg-primary-color);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0 !important;
    z-index: 99;
    display: none !important;
    // width: 0 !important;
    // transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
    // transform: translate(-170px, 0);
  }
   .descriptionBlock.opened{
    display: flex !important;
    width: 100% !important;
    // transform: translate(0, 0);
    }
    .goBackBtn,
    .btnReqRes {
      display: none;
    }
    .mobileBtnGroup{ 
      display: flex;
      gap: 12px;
    }
    
}
@media (max-width: 768px) {
  .mobileBtnGroup{ 
    flex-direction: column;
  }
}