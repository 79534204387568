.header {
  width: 100%;
  /* min-height: 3rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  gap: 24px;
  border-bottom: 1.5px solid var(--border-primary-color);
  background-color: var(--bg-secondary-color);
  flex-shrink: 0;
  color: var(--text-primary-color);
}

.buttonGroup {
  display: flex;
  align-items: center;
  gap: 12px;
}

.buttonTheme {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 35px;
  width: 35px;
  color: white;
  border: 1.5px solid var(--border-primary-color);
  background-color: var(--bg-secondary-color);
  border-radius: 100%;
  padding: 0;
}
.buttonTheme path {
  fill: var(--text-primary-color);
}

.buttonPlane {
  width: 150px;
  border-radius: 100px;
}

button {
  color: var(--text-primary-color);
}

.burgerMenu {
  display: none;
}
@media (max-width: 1440px) {
  .oldSiteBtn {
    display: none;
  }
}
@media (max-width: 1270px) {
  .searchBar {
    width: 100%;
    max-width: 340px;
  }
}

@media (max-width: 1160px) {
  .burgerMenu {
    display: block;
    order: 3;
  }
  .searchBar {
    order: 2;
    max-width: 100%;
  }
  .buttonGroup {
    order: 1;
  }
  .buttonPlane,
  .buttonTheme {
    display: none;
  }

  .header {
    padding: 12px;
    gap: 12px;
    flex-direction: column;
  }
}

.centerButton {
  margin: 0 auto;
  display: block;
}

.timerButton {
  padding: 4px 8px;
  white-space: nowrap;
  border-radius: 16px;
  background-color: #D7E9FF;
  color: #000000;
  border: none;
}

.timerIcon {
  display: inline-flex;
  border: none;
  padding: 2px 4px;
  background-color: #105BFF;
  border-radius: 16px;
  color: #FFFFFF;
}
/* Добавьте это в ваши CSS-правила */
@media (max-width: 480px) {
  .timerButton {
    padding: 8px 16px; /* Увеличьте отступы для кнопки */
    font-size: 16px; /* Увеличьте размер шрифта для мобильных устройств */
  }

  .timerIcon {
    display: inline-flex; /* Убедитесь, что иконка отображается в кнопке */
    margin-left: 8px; /* Добавьте отступ между текстом и иконкой */
  }

  /* Скрыть текст на мобильных устройствах */
  .timerText {
    display: none; /* Убираем текст на мобильных */
  }
}