@import "./reset";
@import "./variables/global";
@import "./themes/normal";
@import "./themes/dark";
@import "./bootstrap.css";
@import "./blocknote.css";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
button:focus-visible,
input:focus-visible,
a:focus-visible {
  box-shadow: var(--focus-brand-500);
  outline: none;
}
body {
  font: var(--font-base);
  font-size: 14px;
  line-height: 19.6px;
  color: var(--text-primary-color);
  background-color: var(--bg-primary-color);
}
/* .app {
  overflow-x: hidden;
} */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  margin: 0;
}
h1 {
  font-size: 36px;
  line-height: 43.2px;
}

h2 {
  font-size: 24px;
  line-height: 35px;
}

h3 {
  font-size: 20px;
  line-height: 24px;
}

h4 {
  font-size: 16px;
  line-height: 19.2px;
}

h5 {
  font-size: 14px;
  line-height: 17.6px;
}

h6 {
  font-size: 12px;
  line-height: 14.4px;
}

p {
  margin: 0;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.link {
  color: var(--c-brand-500);
}
.link:hover {
  text-decoration: underline;
  color: var(--c-brand-600);
}

.noSelectText {
  user-select: none;

  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}
html {
  scroll-behavior: smooth;
}

.gradientText {
  background: linear-gradient(
    90deg,
    #0084ff 0%,
    #c25fff 16.75%,
    #c974fe 30.06%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  flex-grow: 1;
}

.shortenedText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
