.footer {
  padding: 48px;
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--text-primary-color);
}

.p {
  margin: 0;
  color: var(--text-secondary-color);
  text-align: start;
}
@media (max-width: 1440px) {
  .footer {
    padding: 24px 70px;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 24px;
    gap: 10px;
  }
}

@media (max-width: 430px) {
  .footer {
    padding: 24px 16px;
  }
}
