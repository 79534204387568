.senderName {
  display: flex;
  justify-content: start;
  width: 100%;
  font-size: 13px;
  color: var(--text-primary-color);
  margin-bottom: 5px;
  margin-left: 20px;
  font-weight: bold;
}

.receiverName {
  display: flex;
  justify-content: end;
  width: 100%;
  font-size: 13px;
  color: var(--text-primary-color);
  margin-bottom: 5px;
  font-weight: bold;
  padding-right: 20px;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;
}

.userMessage {
  display: flex;
  align-self: flex-start;
  background-color: white;
  color: #333;
  padding: 24px;
  max-width: 420px;
  font-size: 16px;
  min-width: 60px;
  border-radius: 30px;
  border: 1.5px solid rgba(234, 236, 240, 1);
  word-wrap: break-word;
  overflow-wrap: break-word;
  // word-break: break-all;
  white-space: normal;
}

.assistantMessage {
  align-self: flex-end;
  background-color: rgba(40, 113, 255, 1);
  color: white;
  padding: 24px;
  margin-top: 5px;
  max-width: 420px;
  min-width: 60px;
  border-radius: 30px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  // word-break: break-all;
  white-space: normal;
}

.messageTimeUser {
  display: flex;
  font-size: 12px;
  color: #999;
  margin-top: 4px;
  width: 100%;
  justify-content: start;
  margin-left: 20px;
}

.messageTime {
  display: flex;
  font-size: 12px;
  color: #999;
  margin-top: 4px;
  width: 100%;
  justify-content: end;
  padding-right: 20px;
}
