.pageItemsContainer {
  height: 100vh;
  width: 100%;
  padding: 24px;
  background-color: var(--bg-light-secondary-color);
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.pageItemsBtnBox {
  display: flex;
  gap: 12px;
}

.pageItemsBtn {
  background-color: #2871ff;
  padding: 10px 12px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
  border-radius: 4px;
}

.pageItemsFilter:disabled,
.pageItemsBtn:disabled {
  background-color: #ffffff;
  color: #d0d5dd;
}
.pageItemsFilter:disabled svg path {
  stroke: #d0d5dd;
}

.pageItemsFilter {
  background-color: #ffffff;
  border: 1px solid #f9fafb;
  padding: 10px 12px;
  color: #101018;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 4px;
}
.pageItemsFilterOption {
  border: 1px solid var(--border-primary-color);
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.pageItemsFilterOption.active {
  background-color: var(--c-brand-500);
  color: white;
}

.pageItemsFilterOption.active svg path {
  stroke: white;
}
.pageItemsFilterBox {
  position: absolute;
  border: 1px solid var(--border-primary-color);
  box-shadow: var(--shadow-medium);
  top: 48px;
  right: 0;
  z-index: 12;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-primary-color);
  border-radius: 6px;
  width: 360px;
}
.pageItemsFilterBoxHeader {
  display: flex;
  justify-content: space-between;
  padding: 8px 8px 0 16px;
  width: 100%;
}
.pageItemsFilterBoxHeader > * {
  padding: 10px 0;
  flex-shrink: 0;
}
.pageItemsFilterBoxHeader button {
  padding: 8px;
}
.pageItemsFilterItems {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  width: 100%;
  padding: 0px 8px;
}
.pageItemsFilterBtn {
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  border-radius: 4px;
}

.pageItemsDateGenText {
  color: #667085;
  font-weight: 400;
  font-size: 14px;
  line-height: 18.2px;
}

.pageItemsContentContainer {
  overflow-y: auto;
  height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.pageItemsGrid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  gap: 12px;
}

.pageItemsGrid.twoColumnItems {
  grid-template-columns: repeat(2, 1fr);
}

.pageItemsGridBox {
  background-color: var(--bg-primary-color);
  border-radius: 2px;
  padding: 4px;
  width: 100%;
  height: 100%;
}
.pageItemsImageContainer {
  position: relative;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 260px;
  /* max-height: 260px; */
}

.pageItemsImageContainer img {
  cursor: pointer !important;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
}
.pageItemsGrid.twoColumnItems .pageItemsImageContainer {
  max-height: 518px;
}

.hiddenElement {
  display: none;
}
.pageItemsImageContainer:hover .hiddenElement {
  display: block;
}

.downloadBtn,
.dotsBtn,
.bookmarkBtn {
  position: absolute;
  top: 12px;
}
.downloadBtn svg,
.dotsBtn svg,
.bookmarkBtn svg {
  width: 24px;
  height: 24px;
}

.bookmarkBtn {
  left: 12px;
}
.dotsBtn {
  right: 12px;
}
.dotsBtn .active {
  background-color: var(--c-brand-500);
  border-color: var(--c-brand-500);
}
.dotsBtn .active svg path {
  stroke: white;
}
.downloadBtn {
  top: 64px;
  right: 12px;
}
.pageItemsImageBtnContainer {
  position: absolute;
  bottom: 12px;
  width: 100%;
  padding: 0 12px;
}

.pageItemsImageBtnContainer button {
  width: 100%;
}

.preloaderBg {
  object-fit: cover;
  background-color: var(--bg-secondary-color);
  border: 1px solid var(--border-primary-color);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0;
}
.preloaderBg {
  height: 100%;
  min-height: 250px;
}

.functionsBlock {
  position: absolute;
  z-index: 5;
  top: 0;
  right: 50px;
  background-color: var(--bg-primary-color);
  border-radius: 8px;
  border: 1px solid var(--border-primary-color);
  padding: 6px;
  width: 200px;
  gap: 2px;
}
.functionsBlock button {
  width: 100%;
  display: flex;
  align-items: start;
  padding: 8px;
  font-size: 14px;
}
.mobileHeader {
  display: none;
}

@media (max-width: 1400px) {
  .pageItemsGrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .pageItemsGrid.twoColumnItems .pageItemsImageContainer {
    max-height: 418px;
  }
}

@media (max-width: 1160px) {
  .mobileHeader {
    display: flex;
    justify-content: flex-end;
  }
}
@media (max-width: 991px) {
  .pageItemsGrid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .pageItemsContainer {
    padding: 12px;
  }
  .pageItemsGrid.twoColumnItems {
    grid-template-columns: 1fr;
  }
  /* .pageItemsGrid {
    grid-template-columns: 1fr;
  } */
}

@media (max-width: 450px) {
  .pageItemsBtn,
  .pageItemsFilter {
    padding: 8px;
  }
}

@media (max-width: 415px) {
  .pageItemsFilter span {
    display: none;
  }
}
